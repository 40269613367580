import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Info from "./pages/Info";
import Menu from "./pages/Menu";
import Checkout from "./pages/Checkout";
import Pay from "./pages/Pay";
import Success from "./pages/Success";
import Error from "./pages/Error";

function AppRoutes() {
    return (
        <Routes>
            <Route exact path="/:token/" element={<Home />} />
            <Route exact path="/:token/info" element={<Info />} />
            <Route exact path="/:token/menu" element={<Menu />} />
            <Route exact path="/:token/menu/checkout" element={<Checkout />} />
            <Route exact path="/:token/menu/pay" element={<Pay />} />
            <Route exact path="/:token/success" element={<Success />} />
            <Route exact path="/:token/error" element={<Error />} />
        </Routes>
    );
}

export default AppRoutes;

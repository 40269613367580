import React from 'react';
import client from '../../apis/business';
import restaurantReducer, { defaultState } from '../reducers/restaurant-reducer';
import actions from '../actions/restaurant';
import dl from '../../utils/device-language';

const RestaurantContext = React.createContext();

function RestaurantProvider(props) {
    const [state, setState] = React.useReducer(restaurantReducer, defaultState);

    const { isLoading, restaurant, error } = state;

    const run = React.useCallback(async (promise) => {
        return promise.then(
            restaurant => setState(actions.success(restaurant)),
            error => setState(actions.error(error))
        );
    }, []);

    const hasRestaurant = Boolean(restaurant);
    const setRestaurant = React.useCallback(async id => {
        if (!hasRestaurant) {
            setState(actions.fetching(id));
            await run(client.getBusiness(id, dl.getApiLanguage()));
        }
    }, [run, hasRestaurant]);

    const value = React.useMemo(() => {
        return {
            error,
            isLoading,
            restaurant,
            setRestaurant,
        }
    }, [error, isLoading, restaurant, setRestaurant]);

    return <RestaurantContext.Provider value={value} {...props} />;
}

function useRestaurant() {
    const context = React.useContext(RestaurantContext);
    if (!context) {
        throw new Error("useRestaurant must be used within a RestaurantProvider");
    }
    return context;
}

export { RestaurantProvider, useRestaurant }

import { useTranslation } from "react-i18next";
import { trackDataV2 } from "../apis/tracking";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useRestaurant } from "../logic/contexts/restaurant-context";
import { useEffect } from "react";

function Info() {
    const { t } = useTranslation();
    const { restaurant } = useRestaurant();

    useEffect(() => {
        if (restaurant) {
            trackDataV2(restaurant.id, restaurant.token, "viewinfo", {}, false);
        }
    }, [])

    return (
        <div className="h-screen flex flex-col justify-between px-6 text-white bg-[#2B2B2B]">
            <div className="py-10">
                <Header />

                <p>{restaurant.description}</p>

                <a href={`https://maps.google.com/maps/place/${restaurant.address}`} className="block py-4 underline" target="_blank" rel="noopener">{restaurant.address}</a>

                <p className="pt-6 text-lg font-bold">Orari</p>
                {restaurant.timetables_formatted.map(day =>
                    <div className="flex justify-between">
                        <span>{day.day_of_week_formatted}</span>
                        <span>{day.hours}</span>
                    </div>
                )}
            </div>

            <Footer />
        </div>
    );
}

export default Info;

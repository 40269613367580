import React, { useEffect } from "react";
import { trackDataV2 } from "../apis/tracking";
import { useGetBusiness } from "../logic/contexts/BusinessContext";
import { useParams } from "react-router";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../logic/contexts/restaurant-context";
import Header from "../components/Header";
import Item from "../components/Item";
import { useLayoutEffect } from "react";

function Menu() {
    const { token } = useParams();
    const { restaurant: business, setRestaurant } = useRestaurant();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        if (!business) {
            setRestaurant(token);
        }
    }, [business]);

    useEffect(() => {
        if (business) {
            trackDataV2(business.id, business.token, "viewmenu", {}, false);
        }
    }, [business]);

    return (
        <div className="p-6 bg-[#2B2B2B]">
            <Header />

            <div className="relative">
                <p className="text-2xl text-white">Menù e pacchi regalo</p>

                <div className="relative pb-20">
                    {business && business.tasting_menu &&
                        <Item business={business} />}
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Menu;

import React, { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useRestaurant } from '../logic/contexts/restaurant-context';
import checkout from '../apis/checkout';
import scalapay from '../apis/scalapay';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import { trackDataV2 } from '../apis/tracking';

function Success() {
    const { token } = useParams();
    const { t } = useTranslation();
    const [restaurant, setRestaurant] = useState();

    useLayoutEffect(() => {
        async function sendToDashboard() {
            let urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has("provider")) {
                let provider = urlParams.get("provider");
                if ("scalapay" == provider) {
                    let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
                    if (storage) {
                        const orderToken = urlParams.get("orderToken");
                        let payload = JSON.parse(storage);
                        setRestaurant(payload.restaurant);
                        payload.order.payed = true;
                        payload.transaction.id = urlParams.get("orderToken");
                        payload.transaction.provider = "scalapay";

                        let data = checkout.build(payload.restaurant, payload.order, payload.transaction);

                        const scalapayResponse = await scalapay.captureOrder(payload.restaurant.id, orderToken);
                        if (scalapayResponse == "APPROVED") {
                            await checkout.doPost(data);
                            await trackDataV2(payload.restaurant.id, payload.restaurant.token, "sendorder", data, payload.restaurant.has_orders);
                            await trackDataV2(payload.restaurant.id, payload.restaurant.token, "completepayment", payload.transaction, payload.restaurant.has_orders)
                        }
                    }
                }
            }
            // window.localStorage.removeItem("__mycontactlessmenu_payload__");
        }

        sendToDashboard();
    }, []);

    return (
        <section id="success-page" className="h-screen flex flex-col justify-between py-6 font-sans text-center bg-[#2B2B2B]">
            <div className="px-10">
                {restaurant && <img className="mx-auto w-20 pb-10" src={restaurant.image_path} />}
                <p className="text-3xl text-white">Il pagamento è andato a buon fine</p>
            </div>

            <img src="/assets/images/checkout_success.png" className="w-60 m-auto p-6" alt="checkout success" />

            <div className="p-10">
                <Link to={`/${token}`} className="block py-4 rounded-full uppercase text-white bg-[#F46139]">torna al menù</Link>
            </div>

            <Footer />
        </section>
    );
}

export default Success;

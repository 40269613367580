import { Link } from "react-router-dom";
import { useCart } from "../logic/contexts/cart-context";
import { useRestaurant } from "../logic/contexts/restaurant-context";

function Header() {
    const { restaurant } = useRestaurant();
    const { total } = useCart();

    return (
        restaurant &&
        <div className="flex items-center pb-6 text-white">
            <div className="flex-1">
                <Link to={`/${restaurant.token}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-10" viewBox="0 0 512 512" ><path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="32" d="M80 160h352M80 256h352M80 352h352" /></svg >
                </Link>
            </div>
            <div className="flex-1">
                <img className="w-14 mx-auto" src={restaurant.image_path} />
            </div>
            <div className="flex-1">
                <Link to={`/${restaurant.token}/menu/checkout`}>
                    <div className="flex items-center justify-end">
                        <p className="text-xl font-bold text-gray-400">{total}€</p>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-10" viewBox="0 0 512 512"><circle cx="176" cy="416" r="16" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><circle cx="400" cy="416" r="16" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M48 80h64l48 272h256" /><path d="M160 288h249.44a8 8 0 007.85-6.43l28.8-144a8 8 0 00-7.85-9.57H128" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /></svg>
                    </div>
                </Link>
            </div>
        </div>
    );
}

export default Header;

import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer";

function Error() {
    const { token } = useParams();

    return (
        <section id="error-page" className="h-screen flex flex-col justify-between py-6 font-sans text-center bg-[#2B2B2B]">
            <div className="px-18">
                {/* {restaurant && <img className="mx-auto w-20 pb-10" src={restaurant.image_path} />} */}
                <p className="text-3xl text-white">Qualcosa è andato storto.</p>
            </div>

            <img src="/assets/images/error_404.png" alt="error" />

            <div className="px-10">
                <Link to={`/${token}`} className="block py-4 rounded-full uppercase text-white bg-[#F46139]">torna al menù</Link>
            </div>

            <Footer />
        </section>
    );
}

export default Error;

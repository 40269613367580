import * as Dialog from '@radix-ui/react-dialog';
import { useGetBusiness } from '../logic/contexts/BusinessContext';
import { trackDataV2 } from '../apis/tracking';
import IngredientList from './IngredientList';
import { t } from "i18next";
import formats from '../utils/formats';

function DishModal(props) {
    const { dish } = props;

    const { business } = useGetBusiness();

    const onClickHandler = () => {
        trackDataV2(business.id, business.token, "dishview", { id: dish.id, name: dish.name }, false);
    }

    return (
        // <Dialog.Root>
        <div className="relative mb-4">
            <div className="flex">
                {dish.image_path && <img src={dish.image_path} className="w-20 h-20 object-cover rounded-xl" alt={dish.name} />}

                <div className="relative w-full px-3 text-left">
                    <div className="h-full flex flex-col justify-between">
                        <div>
                            <p className="pr-4 capitalize">{dish.name}</p>

                            <div className="pt-2 space-x-2">
                                {dish.allergens.map(allergen =>
                                    <img className="inline w-4" alt={allergen.name} src={"/assets/" + allergen.image_url} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        // <Dialog.Portal>
        //     <Dialog.Overlay>
        //         <Dialog.Content className="max-w-md fixed w-full top-0 left-0 right-0 bottom-0 m-auto overflow-y-scroll bg-white">
        //             <div className="flex justify-center items-center">
        //                 <Dialog.Trigger className="outline-none absolute top-0 left-0 mx-8 my-6 rounded-full p-2 bg-white shadow-2xl shadow-black">
        //                     <svg xmlns="http://www.w3.org/2000/svg" className="w-6 text-blue-500" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
        //                 </Dialog.Trigger>

        //                 {dish.image_path && <img className="w-full object-cover" alt={dish.name} src={dish.image_path} />}
        //             </div>

        //             <div className="p-8 py-4 space-y-4">
        //                 <div className="space-y-6">
        //                     <p className="pt-12 text-2xl">{dish.name}</p>
        //                     <p className="text-gray-600">{dish.price}€</p>
        //                     <span className="flex border border-gray-200"></span>

        //                     <p className="text-sm">{dish.description}</p>

        //                     <p className="text-xl font-semibold">{t("dishModal.p_1")}</p>
        //                     <ul className="list-disc px-4">
        //                         {dish.ingredients.map((ingredient, i) => <IngredientList ingredient={ingredient} key={i} />)}
        //                     </ul>

        //                     {dish.has_allergens ?
        //                         <div className="allergens-warning flex items-center justify-center py-6 pr-4 border-t border-t-gray-200">
        //                             <svg className="w-6 flex-none mr-4 fill-current text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><path xmlns="http://www.w3.org/2000/svg" d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" /></svg>
        //                             <div>
        //                                 <p className="text-sm text-red-500">{t("dishModal.p_2")}</p>
        //                             </div>
        //                         </div>
        //                         : null}

        //                     {dish.is_frozen ?
        //                         <div className="frozen-warning flex items-center justify-center py-6 pr-4 border-t border-t-gray-200">
        //                             <img className="flex-none mr-4 text-red-500" alt="" />
        //                             <div>
        //                                 <p className="text-sm text-red-500">{t("dishModal.p_3")}</p>
        //                             </div>
        //                         </div>
        //                         : null}
        //                 </div>
        //             </div>
        //         </Dialog.Content>
        //     </Dialog.Overlay>
        // </Dialog.Portal>
        // </Dialog.Root>
    );
}

export default DishModal;

import AppRoutes from './AppRoutes';
import { RestaurantProvider } from './logic/contexts/restaurant-context';
import './App.css';

function App() {
  return (
    <div className="container mx-auto max-w-md">
      <RestaurantProvider>
        <AppRoutes />
      </RestaurantProvider>
    </div>
  )
}

export default App;

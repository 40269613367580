
import React from "react";
import { useState } from "react";
import scalapay_client from "../apis/scalapay";
import { trackDataV2 } from "../apis/tracking";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Pay() {
    const [loading, setLoading] = useState(false);

    const onSubmitHandler = async (evt) => {
        evt.preventDefault();
        const name = evt.target.elements["name"].value;
        const surname = evt.target.elements["surname"].value;
        const phone = evt.target.elements["phone"].value;
        const email = evt.target.elements["email"].value;
        const street = evt.target.elements["street"].value;
        const cap = evt.target.elements["cap"].value;

        const customer = {
            "name": name,
            "surname": surname,
            "phone": phone,
            "street": street,
            "email": email,
            "time": "00:00",
            "addressComponents": {
                "zipCode": cap,
                "countryAbbr": "IT"
            }
        };
        let payload = window.localStorage.getItem("__mycontactlessmenu_payload__");
        payload = JSON.parse(payload);

        payload.order.customer = customer;
        window.localStorage.setItem("__mycontactlessmenu_payload__", JSON.stringify(payload));

        try {
            setLoading(true);

            let res = await scalapay_client.createOrder(payload);

            if (res.token) {
                await trackDataV2(payload.restaurant.id, payload.restaurant.token, "sendpayment", { provider: "scalapay" }, payload.restaurant.has_orders)
                window.location.replace(res.checkoutUrl);
            }
        } catch (e) {
            alert("problema con l'elaborazione del pagamento, riprova");
        }
    }

    return (
        <div className="flex flex-col justify-between p-6 bg-[#2B2B2B]">
            <div className="">
                <Header />

                <form className="my-10 p-2 space-y-6" onSubmit={onSubmitHandler}>
                    <input name="name" className="w-full p-3 text-white border-white bg-[#2B2B2B] border rounded-xl" placeholder="Nome" type="text" />
                    <input name="surname" className="w-full p-3 text-white border-white bg-[#2B2B2B] border rounded-xl" placeholder="Cognome" type="text" />
                    <input name="phone" className="w-full p-3 text-white border-white bg-[#2B2B2B] border rounded-xl" placeholder="Telefono" type="tel" />
                    <input name="email" className="w-full p-3 text-white border-white bg-[#2B2B2B] border rounded-xl" placeholder="Email" type="email" />
                    <input name="street" className="w-full p-3 text-white border-white bg-[#2B2B2B] border rounded-xl" placeholder="Indirizzo" type="text" />
                    <input name="cap" className="w-full p-3 text-white border-white bg-[#2B2B2B] border rounded-xl" placeholder="CAP" type="text" pattern="^[0-9]{5}$" required />

                    {!loading ?
                        <button type="submit" className="w-full mt-10 py-2 text-white uppercase rounded-full bg-[#F46139]">paga con scalapay</button>
                        : <svg className="mx-auto w-32" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <circle cx="50" cy="50" fill="none" stroke="#F46139" stroke-width="3" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                            </circle>
                        </svg>}
                </form>
            </div>

            <Footer />
        </div>
    );
}

export default Pay;

import apiClient from "./api-client";

function randomOrderID() {
    return "ord_" + (Date.now().toString(36) + "_" + Math.random().toString(36).substr(-5)).toLowerCase();
}

function build(restaurant, order, transaction) {
    const payload = {
        "restaurant": {
            "id": restaurant.id,
            "name": restaurant.name,
            "phone": restaurant.phone_number,
            "token": restaurant.token,
        },
        "order": {
            "amount": order.amount,
            "commissions": order.commissions,
            "coupon": order.coupon,
            "customer": order.customer,
            "customers": order.customers,
            "id": randomOrderID(),
            "number": order.number,
            "payed": order.payed,
            "products": order.products,
            "quantity": order.quantity,
            "table": order.table,
            "timestamp": order.timestamp,
            "total": order.total,
            "type": order.type,
            "note": order.note,
        },
        "transaction": {
            "id": transaction.id,
            "provider": transaction.provider,
        },
    };

    return payload;
}

async function doPost(data) {
    const PATH = "https://orders.mycontactlessmenu.mycia.it/api/webapp/orders";

    try {
        let response = await apiClient.doPost(PATH, JSON.stringify({ data: data }));
        return response;
    } catch (err) {
        console.log(err);
    }
}

export default {
    randomOrderID,
    build,
    doPost,
}

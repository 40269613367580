import { useCart } from "../logic/contexts/cart-context";
import ItemDescription from './ItemDescription';

function Item(props) {
    const { business } = props;

    const { add } = useCart();

    const addItemHandler = (item) => {
        add(item);
        alert(item.name + "\n\n" + "è stato aggiunto al carrello");
    }

    return (
        business && business.tasting_menu.map(item =>
            <div className="my-6 rounded-xl bg-[#F0EDE9]">
                <img src={item.image_url} className="w-full h-40 object-cover rounded-t-xl" />
                <div className="relative p-3 space-y-2 text-sm ">
                    <div className="flex justify-between">
                        <p className="font-bold">{item.name}</p>
                        <p>{item.price}€</p>
                    </div>

                    <p>{item.description}</p>

                    <ItemDescription business={business} id={item.id} name={item.name} courses={item.courses} />
                </div>

                <button onClick={() => addItemHandler(item)} className="w-full p-2 space-x-2 text-white uppercase bg-[#F46139] rounded-b-xl">
                    <span className="text-2xl font-bold">+</span>
                    <span>aggiungi al carrello</span>
                </button>
            </div>
        )
    );
}

export default Item;

import client from "./api-client";
import formats from "../utils/formats";
import { ENDPOINT, TOKENENDPOINT } from "./constants";

async function getBusiness(id, lang) {
    const isNumeric = /^\d{1,5}$/.test(id);

    let url = isNumeric ? ENDPOINT : TOKENENDPOINT;
    let config = isNumeric ? { "lang": lang, "id": id } : { "lang": lang, "token": id };

    let response = await client.doGet(formats.formatUrl(url, config));

    return response;
}

export default { getBusiness }


// async function getBusiness(id, lang) {
//     const isId = /^\d{1,5}$/.test(id);

//     var response;

//     if (isId) {
//         response = await client.doGet(formats.formatUrl(ENDPOINT, { "lang": lang, "id": id }), {});
//     } else {
//         response = await client.doGet(formats.formatUrl(TOKENENDPOINT, { "lang": lang, "token": id }), {});
//     }

//     return response;
// }



// async function doGetAGP(lang, id) {
//     const isNumeric = /^\d{1,5}$/.test(id);

//     let url = isNumeric ? ENDPOINT : TOKENENDPOINT;
//     let config = isNumeric ? {} : {};

//     let response = await window.fetch(formats.formatUrl(url, config));

//     return response.json();
// }

import { useEffect, useState } from "react";
import dishesClient from "../../apis/dishes";
import { useTranslation } from "react-i18next";

function useBusinessDishes(props) {
    const { tasteId, id } = props;

    const [dishes, setDishes] = useState([]);
    const [loading, setLoading] = useState(true);

    const { i18n } = useTranslation();

    useEffect(() => {
        async function handleBusinessDishesLoad() {
            const res = await dishesClient.doGet(tasteId, id, i18n.language);
            setDishes(res.dishes);
            setLoading(false);
        }

        handleBusinessDishesLoad();
    }, [id]);

    return { loading, dishes };
}

export default useBusinessDishes;

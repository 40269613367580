import { useCart } from "../logic/contexts/cart-context";
import Header from "../components/Header";
import Reviews from "../components/Reviews";
import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router";
import { useRestaurant } from "../logic/contexts/restaurant-context";
import { trackDataV2 } from "../apis/tracking";

function Checkout() {
    const { token } = useParams();
    const { products, quantity, total } = useCart();
    const { restaurant } = useRestaurant()
    const navigate = useNavigate();

    const onCheckoutHandler = () => {
        const storage = {};

        storage["restaurant"] = restaurant;
        storage["order"] = {
            "amount": total,
            "commissions": 0,
            "customer": {},
            "customers": 1,
            "number": -1,
            "products": products,
            "quantity": quantity,
            "table": -1,
            "timestamp": Date.now(),
            "total": total,
            "type": "bundle",
            "note": "",
        };
        storage["transaction"] = {
            "id": "",
            "provider": "scalapay",
        }

        window.localStorage.setItem("__mycontactlessmenu_payload__", JSON.stringify(storage));
        trackDataV2(restaurant.id, restaurant.token, "initpayment", { provider: "scalapay" }, restaurant.has_orders);

        navigate(`/${token}/menu/pay`);
    }

    return (
        <div className="checkout relative p-6 text-white bg-[#2B2B2B]">
            <Header />

            <p className="text-xl">Smart checkout</p>

            <Reviews products={products} />

            <div className="w-full flex flex-col justify-center px-3 py-4 border-t-2 border-[#707070] bg-[#2B2B2B]">
                <div className="flex justify-between text-lg">
                    <p>Totale</p>
                    <p id="total">{total}€</p>
                </div>

                <div className="py-3 text-sm text-white fill-white stroke-white">
                    <scalapay-widget
                        frequency-number="30"
                        number-of-installments="3"
                        hide="false"
                        hide-price="false"
                        min="0"
                        max="2000"
                        amount-selectors='["#total"]'
                        currency-position="before"
                        currency-display="symbol"
                        logo-size="100"
                        theme="primary"
                        locale="it">
                    </scalapay-widget>
                </div>

                {quantity ?
                    <button onClick={onCheckoutHandler} type="button" className="block py-2 text-white uppercase rounded-full bg-[#F46139]">Pagamento</button> :
                    <button type="button" className="block py-2 uppercase rounded-full bg-gray-200">Carrello vuoto</button>}

                <div className="text-center mt-10">
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default Checkout;

import apiClient from "./api-client";
import format from "../utils/formats";

export const ENDPOINT = "https://orders.mycontactlessmenu.mycia.it/api/scalapay/:id/orders";

async function createOrder(payload) {
    try {
        const data = { data: payload }
        var response = await apiClient.doPost(format.formatUrl(ENDPOINT, { id: payload.restaurant.id }), JSON.stringify(data));

        return response;
    } catch (e) {
        console.log({ e });
    }

}

async function captureOrder(resId, token) {
    try {
        var response = await apiClient.doGet(`${format.formatUrl(ENDPOINT, { id: resId })}/${token}/capture`);

        return response;
    } catch (e) {
        console.log({ e });
    }

}

export default {
    createOrder,
    captureOrder,
}

import * as Accordion from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { trackDataV2 } from '../apis/tracking';
import Dishes from './Dishes';

function ItemDescription(props) {
    const { business, id, name, courses } = props;

    const onClickHandler = (e) => {
        let el = e.currentTarget;
        let svg = el.querySelector("svg");

        el.dataset.state === "open" ?
            svg.classList.remove("rotate-180") :
            svg.classList.add("rotate-180", "duration-200", "ease-in-out")

        el.dataset.state === "closed" &&
            trackDataV2(business.id, business.token, "viewitem", { id, name }, false)
    }

    return (
        <Accordion.Root type="multiple">
            <Accordion.Item value={`item-${id}`} className="uppercase">
                <Accordion.Header>
                    <Accordion.Trigger onClick={onClickHandler} className="w-full flex items-center justify-between">
                        <span className="block pt-2 uppercase font-bold">scopri cosa contiene</span>
                        <ChevronDownIcon aria-hidden />
                    </Accordion.Trigger>
                </Accordion.Header>

                <Accordion.Content className="py-4">
                    {courses.map(course =>
                        <div className="">
                            <p className="capitalize font-bold">{course.name}</p>
                            <Dishes id={course.id} tasteId={id} />
                        </div>)}
                </Accordion.Content>
            </Accordion.Item>
        </Accordion.Root>
    );
}

export default ItemDescription;
